<template>
  <div class="h-full cust_card p-2 mt-2" style="overflow: visible !important;">
    <div class="heading-3 text-text1">● Organization List:</div>
    <div class="bottom_space rounded py-2 h-full pt-4 bg-white">
      <div class="flex items-center flex-wrap pb-2">
        <div class="">
          <button :class="!showInActive ? 'bg-white border-gray2' : 'bg-info border-info'" class=" mr-2 text-sm border hover:text-primary py-2.5  text-text1 rounded-md list_btn" @click="getSourceListArray(true)">Active</button>
          <button :class="!showInActive ? 'bg-info border-info' : 'bg-white border-gray2'" class=" mr-2  text-sm border hover:text-primary py-2.5 text-text1 rounded-md list_btn" @click="getSourceListArray(false)">Inactive</button>
        </div>
      </div>
      <div class="bottom_space flex justify-between">
          <div class="mb-2 flex">
            <div class="max-w-max mr-4">
              <div class="select_button text-sm text-text2">
              <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
              </div>
            </div>
            <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-11 mr-4">
              <span class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
              <input class="focus:outline-none w-72 text-text2" autocomplete="off" type="text" name="" placeholder="Search" id="custSearchInput"
                v-model="searchForCustomer"
                @input="getContactListDeboucne"
              >
              <span v-if="searchForCustomer.length > 0" class="cursor-pointer"><img @click="clearSearch()" height="12px" width="12px" src="@/assets/images/svg/clear-input.svg" alt="clearInput"></span>
            </div>
          </div>
        </div>
      <div>
      <div class="w-full">
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder ">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 p-1 items-center">
          <div class="name_col flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[0].isSel ? 'text-primary' : ''" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell2_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[5].isSel ? 'text-primary' : ''" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
            <img class="pl-1" v-if="titleObj[5].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[5].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[1].isSel ? 'text-primary' : ''" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[2].isSel ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell2_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[3].isSel ? 'text-primary' : ''"  @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[6].isSel ? 'text-primary' : ''" @click="sortByColumn(6)">{{titleObj[6].title}}</span>
            <img class="pl-1" v-if="titleObj[6].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[6].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="price_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[7].isSel ? 'text-primary' : ''" @click="sortByColumn(7)">{{titleObj[7].title}}</span>
            <img class="pl-1" v-if="titleObj[7].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[7].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="price_col  flex items-center p-2 ">
            <span class="cursor-pointer" :class="titleObj[4].isSel ? 'text-primary' : ''" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
        </div>
        <div v-for="(data, index) in organizationList" :key="index" class="min-w-min group flex border-r border-l border-b border-gray1  bg-white  text-sm p-1 items-center cursor-pointer hover:bg-gray-50" @click="routingToDetail(data)">
          <div class="name_col p-2">
            <span class="heading-5 " :class="data.isExpired ? 'text-error' : 'text-text2'" v-if="data.organizationName !== ''">{{data.organizationName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell2_col p-2 ">
            <span class="heading-5 text-text1" v-if="data.organizationCode !== ''">{{data.organizationCode}} </span>
          </div>
          <div class="name_col p-2">
            <span class="heading-5 text-text2 " v-if="data.ownerFirstName !== '' || data.ownerLastName !== ''">{{data.ownerFirstName}} {{data.ownerLastName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2">
            <span class="heading-5 text-text2" v-if="data.ownerEmailAddress !== ''">{{data.ownerEmailAddress}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell2_col p-2">
            <span class="heading-5 text-text2" v-if="data.ownerCellNumber !== ''">{{data.ownerCellNumber | phone}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2">
            <span class="heading-5 text-text2 overFlowParaA" v-if="data.currentPlanName !== ''">{{data.currentPlanName}} 
              <!-- <span v-if="data.isFreePlan"><i class="fas fa-dollar text-gray4 heading-7"></i></span>
              <span v-if="!data.isFreePlan"><i class="fas fa-dollar text-success heading-7"></i></span> -->
            </span>
            <span class="" v-else>--</span>
          </div>
          <div class="price_col p-2">
            <span class="heading-5" :class="data.isExpired ? 'text-error' : 'text-text2'" v-if="data.currentPlanExpiryDate !== ''">{{data.currentPlanExpiryDate | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="price_col p-2">
            <span class="heading-5 text-text2" v-if="data.createdOn !== ''">{{data.createdOn | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="organizationList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalEmpCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
</div>
</template>
<script>
import moment from 'moment'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
// import Pagination from '@/View/components/pagination.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Pagination,
    SimpleDropdown,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      showInActive: true,
      mobileView: false,
      searchLoader: false,
      resetPaginationValues: false,
      searchForCustomer: '',
      titleObj: [
        {title: 'Organization Name', icon: '', sortName: 'organizationName', sortVal: '', isSel: false},
        {title: 'Owner Name', icon: '', sortName: 'ownerFirstName', sortVal: '', isSel: false},
        {title: 'Email', icon: '', sortName: 'ownerEmailAddress', sortVal: '', isSel: false},
        {title: 'Cell', icon: '', sortName: 'ownerCellNumber', sortVal: '', isSel: false},
        {title: 'Created Date', icon: 'fas fa-sort-amount-down', sortName: 'createdOn', sortVal: 'DESC', isSel: true},
        {title: 'Organization Code', icon: '', sortName: 'organizationCode', sortVal: '', isSel: false},
        {title: 'Plan Name', icon: '', sortName: 'currentPlanName', sortVal: '', isSel: false},
        {title: 'Expires On', icon: '', sortName: 'currentPlanExpiryDate', sortVal: '', isSel: false},
    ],
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'createdOn',
        sortOrder: 'DESC',
        isActive: true,
      },
      totalEmpCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      organizationList: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler();
    document.title = 'Organization List'
    this.getContactListDeboucne = this.debounce(function () {
      if (this.searchForCustomer.length > 2) {
        this.resetPaginationValues = true
        this.searchLoader = true
      }
      console.log('debounde')
    }, 500)
    this.getEmployeeList()
  },
  watch: {
    searchForCustomer: {
      handler () {
        if (this.searchForCustomer === null) {
          this.searchForCustomer = ''
        }
        if (this.searchForCustomer === '') {
          this.resetPaginationValues = true
          this.getEmployeeList()
        }
      }
    },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getEmployeeList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    addNewComp () {
      this.$router.push({name: 'AddNewCompetition'})
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    clearSearch () {
      this.searchForCustomer = ''
      this.searchLoader = false
      this.getEmployeeList()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getEmployeeList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          console.log('this.titleObj[i].icon', this.titleObj[i].icon)
          console.log('this.titleObj[i].sortVal', this.titleObj[i].sortVal)
          console.log('this.filterObject.sortOrder', this.filterObject.sortOrder)
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].isSel = true
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
            this.titleObj[i].isSel = true
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
            this.titleObj[i].isSel = true
          }
      } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
          this.titleObj[i].isSel = false
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getEmployeeList(true)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    routingToDetail (data) {
      this.$router.push({name: 'OrganizationDetail', params: {orgId: data.organizationId}})
    },
    getSourceListArray (data) {
      this.showInActive = data
      this.filterObject.isActive = data
      this.getEmployeeList()
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getEmployeeList()
    },
    getEmployeeList () {
      if (this.searchForCustomer === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      ADMINAPI.getOrganizationList(
        this.filterObject.isActive,
        this.filterObject.start,
        this.filterObject.length,
        this.searchForCustomer,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          this.organizationList = []
          let TEMP = response.Data.tableRow === null ? [] : response.Data.tableRow
          let TodayDate = new Date().setHours(0, 0, 0);
          for (let index = 0; index < TEMP.length; index++) {
            let ExpireDate = new Date(TEMP[index].currentPlanExpiryDate)
            if (moment(TodayDate).format('YYYY/MM/DD') > moment(ExpireDate).format('YYYY/MM/DD')) {
              TEMP[index].isExpired = true
            } else {
              TEMP[index].isExpired = false
            }
          }
          this.organizationList = TEMP
          console.log('this.organizationList', this.organizationList)
          this.totalEmpCount = response.Data.count
          console.log('count', this.totalEmpCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 270px !important;
  width: 270px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell2_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 210px !important;
  width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.price_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #307dea;
  width: 27px;
  height: 27px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
